import React, { Component } from 'react';
// Composants
import OrganizationSidebar from '../Navbars/OrganizationSidebar';
import SubscriptionRightList from '../Lists/SubscriptionRightList';
import ThemeList from '../Lists/ThemeList';
import CustomFieldList from '../Lists/CustomFieldList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleDollar, faPalette, faPenField, faSackDollar, faAddressCard, faBook } from '@fortawesome/pro-solid-svg-icons'
import { faClock } from '@fortawesome/pro-regular-svg-icons';
import PriceListList from '../Lists/PriceListList';
import BillingAddressForm from '../Forms/Organizations/BillingAddressForm';
import OrganizationMembers from '../Forms/Organizations/OrganizationMembers';
import InfoIcon from '../Utils/InfoIcon';
// Librairies
import { isMobileOnly, isMobile } from 'react-device-detect';
import { differenceInMinutes } from 'date-fns';
import Cookies from 'universal-cookie';
import jwt_decode from 'jwt-decode';
import i18n from '../../locales/i18n';
// Redux
import { connect } from 'react-redux';
import { setProject, setViewProjectAsData } from '../../actionCreators/projectsActions';
import { setActiveOrganization } from '../../actionCreators/usersActions';
// Semantic UI
import { Divider, Grid, Label, Popup, Segment } from 'semantic-ui-react';
// Services
import SubscriptionsService from '../../services/SubscriptionsService';
import OrganizationsService from '../../services/OrganizationsService';
// Utils
import DatesUtil from '../../utils/DatesUtil';
import UsersUtil from '../../utils/UsersUtil';
import OrganizationForm from '../Forms/Organizations/OrganizationForm';
import OrganizationProjects from '../Forms/Organizations/OrganizationProjects';
import { t } from 'i18next';

class OrganizationPanel extends Component {
    state = {
        activeItem: 'general',
        isPro: false,
        billingAddress: null,
        externalAuthentications: [],
        linkedAccounts: [],
        userSubscriptionConsumptions: [],
        isLoading: false
    };

    render() {
        const { activeOrganization } = this.props;
        const { activeItem, billingAddress, isPro, userSubscriptionConsumptions } = this.state;
        let daysLeft, daysLeftMessage, expirationDate = activeOrganization?.subscription.expirationDate;

        if (expirationDate) {
            let hours = (differenceInMinutes(DatesUtil.convertUTCDateToDate(expirationDate), new Date()) / 60).toFixed(0);
            if (hours > 24) daysLeft = Math.floor(hours / 24) + 'j';
            else {
                let minutes = Math.ceil(60 * ((differenceInMinutes(DatesUtil.convertUTCDateToDate(expirationDate), new Date()) / 60) % 1));
                daysLeft = hours + ':' + minutes;
            }

            const date = DatesUtil.getFormattedLocaleDateString(expirationDate);
            daysLeftMessage = i18n.t("Expiration le {{date}}", { date, interpolation: { escapeValue: false } });
        }

        const gridStyle = {
            margin: 0, paddingTop: isMobile ? '0px' : '30px', paddingBottom: isMobile ? '0px' : '30px',
            flexGrow: 1, display: 'block', height: '80vh'
        };

        return (
            <>
                {activeOrganization &&
                    <Grid style={gridStyle} className='grality-background'>
                        <Grid.Row style={{ padding: 0, height: '100%', maxWidth: isMobile ? '100vw' : '80vw', marginLeft: 'auto', marginRight: 'auto' }} stretched>
                            <Grid.Column computer={4} tablet={2} mobile={2} style={{ padding: 0 }}>
                                <OrganizationSidebar activeItem={activeItem} setActiveItem={this.setActiveItem} />
                            </Grid.Column>
                            <Grid.Column computer={12} tablet={14} mobile={14} style={{ padding: 0, height: '100%', overflowY: 'hidden', borderRadius: '0 5px 5px 0' }}>
                                <Segment style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, height: '100%', overflowY: 'overlay' }}>
                                    <Grid style={{ height: ['themes', 'customFields', 'priceLists'].includes(activeItem) ? 'calc(100% + 28px)' : null }}>
                                        {activeItem === 'general' &&
                                            <>
                                                <Grid.Row>
                                                    <Grid.Column>
                                                        <Segment style={isMobileOnly ? { marginBottom: '30px' } : null}>
                                                            <h3><FontAwesomeIcon icon={faBook} style={{ marginRight: '10px' }} />{i18n.t("Informations générales")}</h3>
                                                            <Divider />
                                                            <OrganizationForm />
                                                        </Segment>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column>
                                                        <Segment style={isMobileOnly ? { marginBottom: '30px' } : null}>
                                                            <h3><FontAwesomeIcon icon={faAddressCard} style={{ marginRight: '10px' }} />{i18n.t("Adresse de facturation")}</h3>
                                                            <Divider />
                                                            <BillingAddressForm
                                                                subscribing={false} billingAddress={billingAddress}
                                                                setBillingAddress={(ba) => this.setState({ billingAddress: ba })}
                                                                isPro={isPro} setIsPro={(state) => this.setState({ isPro: state })}
                                                            />
                                                        </Segment>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </>}
                                        {activeItem === 'members' &&
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <OrganizationMembers />
                                                </Grid.Column>
                                            </Grid.Row>}
                                        {activeItem === 'projects' &&
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <OrganizationProjects userSubscriptionConsumptions={userSubscriptionConsumptions} setUserSubscriptionConsumptions={(usc) => this.setState({ userSubscriptionConsumptions: usc })} />
                                                </Grid.Column>
                                            </Grid.Row>}
                                        {activeItem === 'subscription' &&
                                            <>
                                                <Grid.Row centered stretched>
                                                    <Grid.Column>
                                                        <Segment>
                                                            {activeOrganization &&
                                                                <>
                                                                    <h3 style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <FontAwesomeIcon icon={faCircleDollar} style={{ marginRight: '10px' }} /> {i18n.t("Avantages de votre licence")}
                                                                        {daysLeft && <Popup
                                                                            position='bottom right' content={daysLeftMessage} disabled={isMobileOnly}
                                                                            trigger={
                                                                                <Label color='red' style={{ display: 'flex', marginLeft: 'auto' }}>
                                                                                    <FontAwesomeIcon icon={faClock} style={{ marginLeft: 0, marginRight: '10px' }} />{daysLeft}
                                                                                </Label>
                                                                            }
                                                                        />}
                                                                    </h3>
                                                                    <Divider />
                                                                    <SubscriptionRightList userSubscriptionConsumptions={UsersUtil.groupUserSubscriptionConsumptionsPerUsers(userSubscriptionConsumptions)} />
                                                                </>}
                                                        </Segment>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </>}
                                        {activeItem === 'themes' && activeOrganization.subscription?.branding &&
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <Segment style={{ marginBottom: isMobileOnly && '30px', display: 'flex', flexDirection: 'column', height: '100%' }}>
                                                        <h3 style={{ marginBottom: 0 }}><FontAwesomeIcon icon={faPalette} style={{ marginRight: '10px' }} />{i18n.t("Thèmes")}</h3>
                                                        <Divider />
                                                        <ThemeList logged={this.props.logged} />
                                                    </Segment>
                                                </Grid.Column>
                                            </Grid.Row>}
                                        {activeItem === 'customFields' && activeOrganization.subscription?.customFields &&
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <Segment style={{ marginBottom: isMobileOnly && '30px', display: 'flex', flexDirection: 'column', height: '100%' }}>
                                                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                            <h3 style={{ margin: 0 }}><FontAwesomeIcon icon={faPenField} style={{ marginRight: '10px' }} />{i18n.t("Champs personnalisés")}</h3>
                                                            <InfoIcon content={
                                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                                    <div>{i18n.t("Personnalisez votre expérience en créant vos propres champs, adaptés à vos besoins spécifiques. Ces champs peuvent être utilisés pour enrichir les différents types d'éléments que vous gérez, comme les arbres, les espaces verts ou les mobiliers.")}</div>
                                                                    <div>{i18n.t("En plus de cela, vous pouvez organiser ces champs dans des catégories. Par exemple, vous pouvez regrouper plusieurs champs liés à la santé d'un arbre dans une catégorie \"État de l'arbre\" ou créer une catégorie \"Maintenance\" pour vos mobiliers afin de les retrouver plus facilement dans vos projets.")}</div>
                                                                </div>
                                                            } iconStyle={{ marginLeft: '3px' }} popupStyle={!isMobileOnly ? { minWidth: '500px' } : {}} />
                                                        </div>
                                                        <Divider />
                                                        <CustomFieldList />
                                                    </Segment>
                                                </Grid.Column>
                                            </Grid.Row>}
                                        {activeItem === 'priceLists' && activeOrganization.subscription?.costEstimation &&
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <Segment style={{ marginBottom: isMobileOnly && '30px', display: 'flex', flexDirection: 'column', height: '100%' }}>
                                                        <h3 style={{ marginBottom: 0 }}><FontAwesomeIcon icon={faSackDollar} style={{ marginRight: '10px' }} />{i18n.t("Prix personnalisés")}</h3>
                                                        <Divider />
                                                        <PriceListList />
                                                    </Segment>
                                                </Grid.Column>
                                            </Grid.Row>}
                                    </Grid>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>}
            </>
        );
    }

    componentDidMount = () => {
        this.props.changeActiveItem('organizationPanel'); // Sélectionne l'onglet gestion de compte dans la Navbar quand elle est affichée
        this.props.setProject(null);
        this.props.setViewProjectAsData(null);
        if (!this.props.logged && !new Cookies().get('token')) this.props.history.push('/');
        else {
            // Chargement de l'adresse de facturation
            OrganizationsService.getBillingAddress().then(billingAddress => {
                const isPro = (billingAddress?.vat && billingAddress.isValidVat) ? true : false;
                if (!billingAddress) billingAddress = null;
                this.setState({ billingAddress: billingAddress, isPro: isPro });
            });

            // Chargement de la consommation de la licence
            SubscriptionsService.getUserSubscriptionConsumptions().then(userSubscriptionConsumptions => this.setState({ userSubscriptionConsumptions }));

            this.setActiveItem(this.props.match.params.category || 'subscription');
        }
    }

    componentDidUpdate = (prevProps) => { // Si l'utilisateur n'est pas connecté, on l'empêche d'accéder au composant gestion de compte
        if (!this.props.logged) this.props.history.push('/login');
        if (prevProps.activeOrganization?.linkedUsers && this.props.activeOrganization?.linkedUsers && prevProps.activeOrganization.linkedUsers.length < this.props.activeOrganization.linkedUsers.length) {
            const { linkedUsers } = this.props.activeOrganization;
            this.setState(prevState => ({ userSubscriptionConsumptions: prevState.userSubscriptionConsumptions.filter(usc => linkedUsers.find(lu => lu.userId === usc.userId)) }));
        }
    }

    leaveSubscriptionSharing = () => {
        this.setState({ isLoading: true });
        SubscriptionsService.removeUserFromSubscription(jwt_decode(new Cookies().get('token')).id, true).then(activeOrganization => {
            this.props.setActiveOrganization(activeOrganization);
            this.setState({ isLoading: false });
        });
    }

    setActiveItem = (item) => {
        this.setState({ activeItem: item }, () => this.props.history.push(`/organizationPanel/${item}`));
    }
}

const mapStateToProps = (state) => {
    return {
        isOnline: state.isOnline,
        activeOrganization: state.activeOrganization,
        tutorialTour: state.tutorialTour,
        loginAsData: state.loginAsData
    };
}

const mapDispatchToProps = {
    setProject,
    setActiveOrganization,
    setViewProjectAsData
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationPanel);